import request from "../axios";

export function batchQueryReportApi(personaId, personaName) {
    return request({
        url: "/report/query/batch",
        method: 'GET',
        params: {
            persona_id: personaId,
            persona_name: personaName,
        }
    })
}