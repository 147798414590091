export const DateUtil = {

    format(timestamp) {
        let date = new Date(timestamp);
        return date.toLocaleString('zh')
    },

    toDurationMinutesString(durationSeconds) {
        return parseInt(durationSeconds / 60)  + '分钟'
    }

}