<template>
  <div>
    <a-card :bordered="false">
      <template #title>
        载具列表<i @click="refresh" style="cursor: pointer;margin-left: 15px" :class="loading ? 'el-icon-loading' : 'el-icon-refresh-right'"></i>
      </template>
      <div v-loading="loading" element-loading-background="#111111" style="padding: 20px">
        <div v-if="vehicleList.length > 0">
          <div @click="expandVehicle(vehicle)" v-for="(vehicle, index) in displayVehicleList" :key="index" style="padding: 10px;background-color: rgba(89,89,89,0.2);color: white;" :style="{'margin-top': (index > 0 ? '20px' : '0'), 'cursor': vehicle['subVehicleList'].length > 1 ? 'pointer' : 'default'}">
            <div style="display: flex;justify-content: space-between;">
              <!--载具图片和名字-->
              <div style="width: 40%;display: flex">
                <div style="width: 50%;display: flex;flex-direction: column;justify-content: space-around;align-items: center">
                  <el-image style="margin-bottom: 10px;width: 180px;height: 45px" :src="vehicle['image']"/>
                  <div style="font-size: 18px;font-weight: 500;line-height: 20px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
                    {{ vehicle['name'] }}
                  </div>
                </div>
                <div v-if="vehicle['subVehicleList'].length > 1" style="width: 50%;">
                  <a-card-grid style="width: 100%;">
                    <a-statistic class="first-data" title="子类型" :value="objectUtil.nullToDefault(vehicle['subVehicleList'].length, '—') + '个'"/>
                  </a-card-grid>
                </div>
              </div>
              <div style="width: 40%;">
                <a-row>
                  <a-col :xs="xs" :sm="sm" :md="md" :lg="lg" :xl="xl" :xxl="xxl">
                    <a-card-grid style="width: 100%;">
                      <a-statistic class="first-data" title="击杀" :value="objectUtil.nullToDefault(vehicle['kills'], '—')"/>
                    </a-card-grid>
                  </a-col>
                  <a-col :xs="xs" :sm="sm" :md="md" :lg="lg" :xl="xl" :xxl="xxl">
                    <a-card-grid style="width: 100%;">
                      <a-statistic class="first-data" title="KPM" :value="objectUtil.nullToDefault(vehicle['kpm'], '—')"/>
                    </a-card-grid>
                  </a-col>
                  <a-col :xs="xs" :sm="sm" :md="md" :lg="lg" :xl="xl" :xxl="xxl">
                    <a-card-grid style="width: 100%;">
                      <a-statistic class="first-data" title="摧毁" :value="objectUtil.nullToDefault(vehicle['destroyed'], '—')"/>
                    </a-card-grid>
                  </a-col>
                  <a-col :xs="xs" :sm="sm" :md="md" :lg="lg" :xl="xl" :xxl="xxl">
                    <a-card-grid style="width: 100%;">
                      <a-statistic class="first-data" title="时长" :value="objectUtil.nullToDefault(vehicle['hoursPlayed'], '—') + '小时'"/>
                    </a-card-grid>
                  </a-col>
                </a-row>
              </div>
            </div>
            <el-collapse-transition>
              <div v-if="vehicle['subVehicleList'].length > 1 && vehicle['expand']" style="border-top: 1px solid white;margin-top: 10px">
                <div v-for="(subVehicle, i) in vehicle['subVehicleList']" :key="i" style="display: flex;justify-content: space-between;margin-top: 10px">
                  <div style="width: 20%;display: flex;flex-direction: column;justify-content: space-around;align-items: center">
                    <el-image style="margin-bottom: 10px;width: 180px;height: 45px" :src="subVehicle['image']"/>
                    <div style="font-size: 18px;font-weight: 500;line-height: 20px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
                      {{ subVehicle['name'] }}
                    </div>
                  </div>
                  <div style="width: 40%;">
                    <a-row>
                      <a-col :xs="xs" :sm="sm" :md="md" :lg="lg" :xl="xl" :xxl="xxl">
                        <a-card-grid style="width: 100%;">
                          <a-statistic class="first-data" title="击杀" :value="objectUtil.nullToDefault(subVehicle['kills'], '—')"/>
                        </a-card-grid>
                      </a-col>
                      <a-col :xs="xs" :sm="sm" :md="md" :lg="lg" :xl="xl" :xxl="xxl">
                        <a-card-grid style="width: 100%;">
                          <a-statistic class="first-data" title="KPM" :value="objectUtil.nullToDefault(subVehicle['kpm'], '—')"/>
                        </a-card-grid>
                      </a-col>
                      <a-col :xs="xs" :sm="sm" :md="md" :lg="lg" :xl="xl" :xxl="xxl">
                        <a-card-grid style="width: 100%;">
                          <a-statistic class="first-data" title="摧毁" :value="objectUtil.nullToDefault(subVehicle['destroyed'], '—')"/>
                        </a-card-grid>
                      </a-col>
                      <a-col :xs="xs" :sm="sm" :md="md" :lg="lg" :xl="xl" :xxl="xxl">
                        <a-card-grid style="width: 100%;">
                          <a-statistic class="first-data" title="时长" :value="objectUtil.nullToDefault(subVehicle['hoursPlayed'], '—') + '小时'"/>
                        </a-card-grid>
                      </a-col>
                    </a-row>
                  </div>
                </div>
              </div>
            </el-collapse-transition>
          </div>
          <div v-if="displayVehicleOffset < vehicleList.length" style="margin-top: 20px">
            <a-button @click="loadMore" style="height: 40px;" size="large" class="hoverButton" ghost block>加载更多载具</a-button>
          </div>
        </div>
        <div v-else style="height: 350px;color: white;display: flex;justify-content: center;align-items: center;font-size: 20px">
          暂无载具数据
        </div>
      </div>
    </a-card>
  </div>
</template>

<script>

import {objectUtil} from "../utils/object-util";

export default {
  name: "vehicleList",
  computed: {
    objectUtil() {
      return objectUtil
    }
  },
  props: {
    vehicleList: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    vehicleList (val) {
      if (val.length > 0) {
        this.displayVehicleList = this.vehicleList.slice(0, this.displayVehicleOffset)
      }
    }
  },
  data() {
    return {
      xxl: 6,
      xl: 4,
      lg: 6,
      md: 6,
      sm: 8,
      xs: 8,
      displayVehicleOffset: 10,
      displayVehicleList: []
    }
  },
  methods: {
    refresh() {
      if (this.loading) {
        return
      }
      this.$emit("refresh")
    },
    loadMore() {
      this.displayVehicleOffset += 10
      this.displayVehicleList = this.vehicleList.slice(0, this.displayVehicleOffset)
    },
    expandVehicle(vehicle) {
      if (vehicle['expand'] === null) {
        this.$set(vehicle, 'expand', true)
      }
      else {
        this.$set(vehicle, 'expand', !vehicle['expand'])
      }
    },
  }
}
</script>

<style scoped>
.ant-card {
  background: rgba(17,17,17,0.7);
}
::v-deep .ant-card-head {
  color: white;
  background-color: #1c1c1c;
  border-bottom: none;
}
::v-deep .ant-card-body {
  padding: 0;
}
.ant-card-grid {
  box-shadow: none;
}
::v-deep .ant-card-head-title {
  font-size: 20px;
}


.hoverButton.ant-btn:hover, .hoverButton.ant-btn:focus {
  background-color: white !important;
  border-color: white !important;
  font-weight: bold;
  color: #4f4f4f;
}
.hoverButton.ant-btn[disabled]:hover, .hoverButton.ant-btn[disabled]:focus {
  color: #4f4f4f;
}
.hoverButton.ant-btn[disabled] {
  color: white;
}

::v-deep .ant-card-grid {
  padding: 0;
}


::v-deep .ant-statistic-title,
::v-deep .ant-statistic-content,
::v-deep .ant-statistic-content .ant-statistic-content-value-int,
::v-deep .ant-statistic-content .ant-statistic-content-value-decimal,
::v-deep .ant-statistic-content .ant-statistic-content-value{
  font-size: 22px;
}
</style>