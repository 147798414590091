<template>
  <div class="custom-scrollbar" style="line-height: 1.5;height: 100%;">
    <a-row type="flex" justify="center" style="padding-top: 10px">
      <a-col :span="20">
        <el-page-header @back="handleBack" style="color: white;margin-bottom: 14px">
          <template #title>
            <div style="font-size: 20px">
              返回
            </div>
          </template>
          <template #content>
            <div style="color: white;font-size: 20px">
              玩家详情
            </div>
          </template>
        </el-page-header>
        <div style="background-color: rgba(17,17,17,0.7);padding: 12px;margin-top: 10px;">
          <div style="display:flex;">
            <img style="background: #2c2c2c" class="emblems" :src="objectUtil.nullToDefault(personaInfo.emblem, DefaultValue.defaultAvatar)" alt=""/>
            <a-row style="flex-grow: 1;margin-left: 10px;">
              <a-row>
                <a-col :xs="0" :sm="24" :md="24" :lg="24" :xl="24" class="personaNameText" style="color: white;font-weight: bold;overflow: hidden;text-overflow: ellipsis">
                  {{ personaInfo.platoonName ? '[' + personaInfo.platoonName + ']' : '' }} {{ objectUtil.nullToDefault(personaInfo.personaName, "—") }}
                </a-col>
              </a-row>
              <a-row>
                <a-col :xs="0" :sm="24" :md="24" :lg="24" :xl="24">
                  <div style="display: flex">
                    <a-statistic class="infoData" title="等级" :value="objectUtil.nullToDefault(personaInfo.rank, '—')"/>
                    <a-statistic style="padding-left: 30px" class="infoData" title="时长" :value="objectUtil.nullToDefault(personaInfo.hoursPlayed, '—') + '小时'"/>
                    <a-statistic v-for="(item, index) in reportList" :key="index" style="padding-left: 30px" class="infoData" :title="item['channelName']">
                      <template #formatter>
                        <a v-if="item['channelUrl']" style="color: #F56C6C;" :href="item['channelUrl']" target="_blank">{{ dict[item['channelCode']][item['channelStatus']] }}</a>
                        <span v-else>{{ dict[item['channelCode']][item['channelStatus']] }}</span>
                      </template>
                    </a-statistic>
                  </div>
                </a-col>
              </a-row>
              <a-row>
                <a-col :xs="24" :sm="0" :md="0" :lg="0" :xl="0">
                  <a-row>
                    <a-col :xs="12" :sm="12" :md="4" :lg="3" :xl="3">
                      <a-statistic class="infoData" title="等级" :value="objectUtil.nullToDefault(personaInfo.rank, '—')"/>
                    </a-col>
                    <a-col :xs="12" :sm="12" :md="4" :lg="3" :xl="3">
                      <a-statistic class="infoData" title="时长" :value="objectUtil.nullToDefault(personaInfo.hoursPlayed, '—') + '小时'"/>
                    </a-col>
                    <a-col v-for="(item, index) in reportList" :key="index" :xs="12" :sm="12" :md="4" :lg="3" :xl="3">
                      <a-statistic class="infoData" :title="item['channelName']">
                        <template slot="formatter">
                          <a v-if="item['channelUrl']" style="color: #F56C6C;" :href="item['channelUrl']" target="_blank">{{ dict[item['channelCode']][item['channelStatus']] }}</a>
                          <span v-else>{{ dict[item['channelCode']][item['channelStatus']] }}</span>
                        </template>
                      </a-statistic>
                    </a-col>
                  </a-row>
                </a-col>
              </a-row>
            </a-row>
          </div>
          <a-row>
            <a-col :xs="24" :sm="0" :md="0" :lg="0" :xl="0" class="personaNameText" style="color: white;font-weight: bold;overflow: hidden;text-overflow: ellipsis">
              {{ personaInfo.platoonName ? '[' + personaInfo.platoonName + ']' : '' }} {{ objectUtil.nullToDefault(personaInfo.personaName, "—") }}
            </a-col>
          </a-row>
        </div>
      </a-col>
    </a-row>

    <a-row type="flex" justify="center" style="padding-bottom: 20px">
      <a-col :span="20">
        <el-tabs v-model="activeTabName" style="margin-top: 20px" type="border-card" @tab-click="handleTabClick">
          <el-tab-pane label="总览" name="stats">
            <a-row :gutter="[20, 20]" type="flex" justify="space-between">
              <a-col :xs="24" :sm="24" :md="24" :lg="24" :xl="16" :xxl="17">
                <a-row :gutter="[20, 20]">
                  <a-col>
                    <a-card :bordered="false">
                      <template #title>
                        主要数据<i @click="refreshStats" style="cursor: pointer;margin-left: 15px" :class="statsLoading ? 'el-icon-loading' : 'el-icon-refresh-right'"></i>
                      </template>
                      <a-row v-loading="statsLoading" element-loading-background="#111111">
                        <a-col :xs="12" :sm="8" :md="6" :lg="6" :xl="6" :xxl="6">
                          <a-card-grid style="width: 100%;">
                            <a-statistic class="baseData" title="击杀数" :value="objectUtil.nullToDefault(personaInfo.kills, '—')"/>
                          </a-card-grid>
                        </a-col>
                        <a-col :xs="12" :sm="8" :md="6" :lg="6" :xl="6" :xxl="6">
                          <a-card-grid style="width: 100%;">
                            <a-statistic class="baseData" title="死亡数" :value="objectUtil.nullToDefault(personaInfo.deaths, '—')"/>
                          </a-card-grid>
                        </a-col>
                        <a-col :xs="12" :sm="8" :md="6" :lg="6" :xl="6" :xxl="6">
                          <a-card-grid style="width: 100%;">
                            <a-statistic class="baseData" title="KD" :value="objectUtil.nullToDefault(personaInfo.kd, '—')"/>
                          </a-card-grid>
                        </a-col>
                        <a-col :xs="12" :sm="8" :md="6" :lg="6" :xl="6" :xxl="6">
                          <a-card-grid style="width: 100%;">
                            <a-statistic class="baseData" title="KPM" :value="objectUtil.nullToDefault(personaInfo.kpm, '—')"/>
                          </a-card-grid>
                        </a-col>
                        <a-col :xs="12" :sm="8" :md="6" :lg="6" :xl="6" :xxl="6">
                          <a-card-grid style="width: 100%;">
                            <a-statistic class="baseData" title="SPM" :value="objectUtil.nullToDefault(personaInfo.spm, '—')"/>
                          </a-card-grid>
                        </a-col>
                        <a-col :xs="12" :sm="8" :md="6" :lg="6" :xl="6" :xxl="6">
                          <a-card-grid style="width: 100%;">
                            <a-statistic class="baseData" title="最远爆头" :value="objectUtil.nullToDefault(personaInfo.longestHeadShot, '—')"/>
                          </a-card-grid>
                        </a-col>
                        <a-col :xs="12" :sm="8" :md="6" :lg="6" :xl="6" :xxl="6">
                          <a-card-grid style="width: 100%;">
                            <a-statistic class="baseData" title="最高连杀" :value="objectUtil.nullToDefault(personaInfo.highestKillStreak, '—')"/>
                          </a-card-grid>
                        </a-col>
                        <a-col :xs="12" :sm="8" :md="6" :lg="6" :xl="6" :xxl="6">
                          <a-card-grid style="width: 100%;">
                            <a-statistic class="baseData" title="场均击杀" :value="objectUtil.nullToDefault(personaInfo.roundAvgKills, '—')"/>
                          </a-card-grid>
                        </a-col>
                        <a-col :xs="12" :sm="8" :md="6" :lg="6" :xl="6" :xxl="6">
                          <a-card-grid style="width: 100%;">
                            <a-statistic class="baseData" title="命中率" :value="objectUtil.nullToDefault(personaInfo.accuracyRatio, '—') + '%'"/>
                          </a-card-grid>
                        </a-col>
                        <a-col :xs="12" :sm="8" :md="6" :lg="6" :xl="6" :xxl="6">
                          <a-card-grid style="width: 100%;">
                            <a-statistic class="baseData" title="爆头率" :value="objectUtil.nullToDefault(personaInfo.headShotsRatio, '—') + '%'"/>
                          </a-card-grid>
                        </a-col>
                        <a-col :xs="12" :sm="8" :md="6" :lg="6" :xl="6" :xxl="6">
                          <a-card-grid style="width: 100%;">
                            <a-statistic class="baseData" title="胜率" :value="objectUtil.nullToDefault(personaInfo.winRatio, '—') + '%'"/>
                          </a-card-grid>
                        </a-col>
                        <a-col :xs="12" :sm="8" :md="6" :lg="6" :xl="6" :xxl="6">
                          <a-card-grid style="width: 100%;">
                            <a-statistic class="baseData" title="游戏场次" :value="objectUtil.nullToDefault(personaInfo.roundsPlayed, '—')"/>
                          </a-card-grid>
                        </a-col>
                      </a-row>
                    </a-card>
                  </a-col>
                  <a-col>
                    <a-card title="次要数据" :bordered="false">
                      <a-row v-loading="statsLoading" element-loading-background="#111111">
                        <a-col :xs="12" :sm="8" :md="6" :lg="6" :xl="6" :xxl="6">
                          <a-card-grid style="width: 100%;">
                            <a-statistic class="baseData" title="治疗数" :value="objectUtil.nullToDefault(personaInfo.heals, '—')"/>
                          </a-card-grid>
                        </a-col>
                        <a-col :xs="12" :sm="8" :md="6" :lg="6" :xl="6" :xxl="6">
                          <a-card-grid style="width: 100%;">
                            <a-statistic class="baseData" title="救援数" :value="objectUtil.nullToDefault(personaInfo.revives, '—')"/>
                          </a-card-grid>
                        </a-col>
                        <a-col :xs="12" :sm="8" :md="6" :lg="6" :xl="6" :xxl="6">
                          <a-card-grid style="width: 100%;">
                            <a-statistic class="baseData" title="维修数" :value="objectUtil.nullToDefault(personaInfo.repairs, '—')"/>
                          </a-card-grid>
                        </a-col>
                        <a-col :xs="12" :sm="8" :md="6" :lg="6" :xl="6" :xxl="6">
                          <a-card-grid style="width: 100%;">
                            <a-statistic class="baseData" title="助攻数" :value="objectUtil.nullToDefault(personaInfo.assists, '—')"/>
                          </a-card-grid>
                        </a-col>
                        <a-col :xs="12" :sm="8" :md="6" :lg="6" :xl="6" :xxl="6">
                          <a-card-grid style="width: 100%;">
                            <a-statistic class="baseData" title="胜场数" :value="objectUtil.nullToDefault(personaInfo.wins, '—')"/>
                          </a-card-grid>
                        </a-col>
                        <a-col :xs="12" :sm="8" :md="6" :lg="6" :xl="6" :xxl="6">
                          <a-card-grid style="width: 100%;">
                            <a-statistic class="baseData" title="败场数" :value="objectUtil.nullToDefault(personaInfo.losses, '—')"/>
                          </a-card-grid>
                        </a-col>
                        <a-col :xs="12" :sm="8" :md="6" :lg="6" :xl="6" :xxl="6">
                          <a-card-grid style="width: 100%;">
                            <a-statistic class="baseData" title="占点数" :value="objectUtil.nullToDefault(personaInfo.flagsCaptured, '—')"/>
                          </a-card-grid>
                        </a-col>
                        <a-col :xs="12" :sm="8" :md="6" :lg="6" :xl="6" :xxl="6">
                          <a-card-grid style="width: 100%;">
                            <a-statistic class="baseData" title="守点数" :value="objectUtil.nullToDefault(personaInfo.flagsDefended, '—')"/>
                          </a-card-grid>
                        </a-col>
                        <a-col :xs="12" :sm="8" :md="6" :lg="6" :xl="6" :xxl="6">
                          <a-card-grid style="width: 100%;">
                            <a-statistic class="baseData" title="复仇击杀" :value="objectUtil.nullToDefault(personaInfo.avengerKills, '—')"/>
                          </a-card-grid>
                        </a-col>
                        <a-col :xs="12" :sm="8" :md="6" :lg="6" :xl="6" :xxl="6">
                          <a-card-grid style="width: 100%;">
                            <a-statistic class="baseData" title="克星击杀" :value="objectUtil.nullToDefault(personaInfo.nemesisKills, '—')"/>
                          </a-card-grid>
                        </a-col>
                        <a-col :xs="12" :sm="8" :md="6" :lg="6" :xl="6" :xxl="6">
                          <a-card-grid style="width: 100%;">
                            <a-statistic class="baseData" title="救星击杀" :value="objectUtil.nullToDefault(personaInfo.saviorKills, '—')"/>
                          </a-card-grid>
                        </a-col>
                        <a-col :xs="12" :sm="8" :md="6" :lg="6" :xl="6" :xxl="6">
                          <a-card-grid style="width: 100%;">
                            <a-statistic class="baseData" title="技巧值" :value="objectUtil.nullToDefault(personaInfo.skill, '—')"/>
                          </a-card-grid>
                        </a-col>
                      </a-row>
                    </a-card>
                  </a-col>
                </a-row>
              </a-col>
              <a-col :xs="24" :sm="24" :md="24" :lg="24" :xl="8" :xxl="7">
                <a-row :gutter="[20, 20]">
                  <a-col :xs="24" :sm="24" :md="12" :lg="12" :xl="24" :xxl="24">
                    <a-card title="兵种信息" :bordered="false">
                      <el-table v-loading="statsLoading" element-loading-background="#111111" @cell-mouse-enter="classTableEnter" @cell-mouse-leave="classTableLeave" :data="personaInfo.kits" :header-cell-style="{color: '#FFFFFF',height: '0'}" :cell-style="{color: '#FFFFFF', 'padding-top': '5px', 'padding-bottom': '5px'}">
                        <el-table-column label="兵种" width="50" align="center">
                          <template v-slot="scope">
                            <img v-if="scope['row']['enter']" style="width: 30px; height: 30px" :src="BF1Kit[scope['row']['index']]['images']['black']" alt="">
                            <img v-else style="width: 30px; height: 30px" :src="BF1Kit[scope['row']['index']]['images']['white']" alt="">
                          </template>
                        </el-table-column>
                        <el-table-column label="击杀" prop="kills" min-width="80" align="center"/>
                        <el-table-column label="KPM" prop="kpm" min-width="70" align="center"/>
                        <el-table-column label="SPM" prop="spm" min-width="90" align="center"/>
                        <el-table-column label="时长" prop="hoursPlayed" min-width="80" align="center"/>
                      </el-table>
                    </a-card>
                  </a-col>
                  <a-col :xs="24" :sm="24" :md="12" :lg="12" :xl="24" :xxl="24">
                    <a-card title="最近游玩" :bordered="false">
                      <el-table v-loading="statsLoading" element-loading-background="#111111" @row-click="navigateToServerDetail" class="server_table" :show-header="false" :data="recentServerList" :header-cell-style="{color: '#FFFFFF',height: '0'}" :cell-style="{color: '#FFFFFF', 'padding-top': '12px', 'padding-bottom': '12px'}">
                        <el-table-column min-width="120" :show-overflow-tooltip="true">
                          <template v-slot="scope">
                            <div>
                              <div style="font-size: 14px;font-weight: 500">
                                {{ scope['row']['name'] }}
                              </div>
                              <div style="font-size: 12px;color: #a8a8a8;margin-top: 1px">
                                {{ Bf1Country[scope['row']['country']] }} - <span :style="{color: getServerTypeTextColor(scope['row']['type'])}">{{ Bf1ServerType[scope['row']['type']] }}</span><span v-if="scope['row']['isCustom']" style="color: #FFD700"> - 自订</span>
                              </div>
                            </div>
                          </template>
                        </el-table-column>
                      </el-table>
                    </a-card>
                  </a-col>
                </a-row>
              </a-col>
            </a-row>
          </el-tab-pane>
          <el-tab-pane label="武器" name="weapon">
            <weapon-list
                :weapon-list="weaponList"
                :loading="weaponLoading"
                @refresh="batchQueryPersonaWeapon"
            />
          </el-tab-pane>
          <el-tab-pane label="载具" name="vehicle">
            <vehicle-list
                :vehicle-list="vehicleList"
                :loading="vehicleLoading"
                @refresh="batchQueryPersonaVehicle"
            />
          </el-tab-pane>
          <el-tab-pane label="对局" name="match">
            <match-list
                :loading="matchLoading"
                :match-list="matchList"
                :page-number="matchPageNumber"
                :page-size="matchPageSize"
                :total="matchTotal"
                @refresh="batchQueryPersonaMatch"
                @page-change="matchPageChange"
            />
          </el-tab-pane>
        </el-tabs>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import {batchQueryReportApi} from "../../../request/api/report";
import {
  batchQueryPersonaMatchApi,
  batchQueryPersonaRecentServerApi, batchQueryPersonaVehicleApi, batchQueryPersonaWeaponApi,
  queryPersonaStatsApi
} from "../../../request/api/persona";
import {objectUtil} from "../../../utils/object-util";
import {DefaultValue} from "../../../enum/default-value";
import {Bf1Country, BF1Kit, Bf1ServerType} from "../../../enum/bf1-enum";
import MatchList from "../../../component/MatchList.vue";
import WeaponList from "../../../component/WeaponList.vue";
import VehicleList from "../../../component/VehicleList.vue";

export default {
  name: "playerInfo",
  components: {VehicleList, WeaponList, MatchList},
  data() {
    return {
      Bf1ServerType: Bf1ServerType,
      Bf1Country: Bf1Country,
      BF1Kit: BF1Kit,
      DefaultValue: DefaultValue,
      objectUtil: objectUtil,
      personaId: -1,
      personaInfo: {
        platoonName: null,
        personaName: null,
        emblem: null,
        rank: null,
        hoursPlayed: null,
        kills: null,
        deaths: null,
        kd: null,
        kpm: null,
        spm: null,
        longestHeadShot: null,
        highestKillStreak: null,
        roundAvgKills: null,
        accuracyRatio: null,
        headShotsRatio: null,
        winRatio: null,
        roundsPlayed: null,
        heals: null,
        revives: null,
        repairs: null,
        assists: null,
        wins: null,
        losses: null,
        flagsCaptured: null,
        flagsDefended: null,
        avengerKills: null,
        nemesisKills: null,
        saviorKills: null,
        skill: null,
        kits: [
          {
            // 突击兵
            index: 1,
            kills: null,
            kpm: null,
            spm: null,
            hoursPlayed: null
          },
          {
            // 支援兵
            index: 3,
            kills: null,
            kpm: null,
            spm: null,
            hoursPlayed: null
          },
          {
            // 医疗兵
            index: 2,
            kills: null,
            kpm: null,
            spm: null,
            hoursPlayed: null
          },
          {
            // 侦察兵
            index: 4,
            kills: null,
            kpm: null,
            spm: null,
            hoursPlayed: null
          },
          {
            // 驾驶员
            index: 7,
            kills: null,
            kpm: null,
            spm: null,
            hoursPlayed: null
          },
          {
            // 飞行员
            index: 6,
            kills: null,
            kpm: null,
            spm: null,
            hoursPlayed: null
          },
          {
            // 骑兵
            index: 5,
            kills: null,
            kpm: null,
            spm: null,
            hoursPlayed: null
          },
        ]
      },
      recentServerList: [
        {
          name: "—",
          country: "—",
          type: "—",
          custom: false,
          gameId: -1,
        }
      ],
      reportList: [
        {
          channelName: 'BFEAC',
          channelCode: 'bfeac',
          channelStatus: 999,
          channelUrl: ''
        },
      ],
      dict: {
        bfeac: {
          '0': '未处理',
          '1': '已封禁',
          '2': '证据不足',
          '3': '已自证',
          '4': '自证中',
          '5': '刷枪',
          '6': '无',
          '999': '—',
        },
      },
      statsLoading: false,
      matchLoading: false,
      matchList: [],
      matchPageNumber: 1,
      matchPageSize: 10,
      matchTotal: 0,
      activeTabName: 'stats',
      latestTabName: 'stats',

      weaponLoading: false,
      vehicleLoading: false,
      weaponList: [],
      vehicleList: [],

      statsInitialized: false,
      matchInitialized: false,
      weaponInitialized: false,
      vehicleInitialized: false,
    }
  },
  created() {
    if (this.$route['query']['personaId']) {
      this.personaId = this.$route['query']['personaId']
    }
    if (this.$route['query']['tab']) {
      this.activeTabName = this.$route['query']['tab']
      this.latestTabName = this.$route['query']['tab']
    }
    if (this.$route['query']['from']) {
      this.$emit('changeKey', this.$route['query']['from'])
    }
    this.batchQueryReport()
    if (this.activeTabName === 'stats') {
      this.refreshStats()
    }
    if (this.activeTabName === 'match') {
      this.queryPersonaStats()
      this.batchQueryPersonaMatch();
    }
    if (this.activeTabName === 'weapon') {
      this.queryPersonaStats()
      this.batchQueryPersonaWeapon();
    }
    if (this.activeTabName === 'vehicle') {
      this.queryPersonaStats()
      this.batchQueryPersonaVehicle()
    }
  },
  methods: {
    classTableEnter(row, column, cell, event) {
      this.$set(row, 'enter', true);
    },
    classTableLeave(row, column, cell, event) {
      this.$set(row, 'enter', false);
    },
    refreshStats() {
      this.queryPersonaStats()
      this.batchQueryPersonaRecentServer()
    },
    handleTabClick(tab, event) {
      if (this.latestTabName === tab.name) {
        return;
      }
      this.latestTabName = tab.name
      this.$router.replace({
        path: "/player/info",
        query: {
          personaId: this.personaId,
          from: 3,
          tab: tab.name
        }
      })
      if (tab.name === 'stats') {
        if (this.statsInitialized) {
          return;
        }
        this.refreshStats();
      }
      if (tab.name === 'match') {
        if (this.matchInitialized) {
          return;
        }
        this.batchQueryPersonaMatch()
      }
      if (tab.name === 'weapon') {
        if (this.weaponInitialized) {
          return;
        }
        this.batchQueryPersonaWeapon()
      }
      if (tab.name === 'vehicle') {
        if (this.vehicleInitialized) {
          return;
        }
        this.batchQueryPersonaVehicle();
      }
    },
    batchQueryPersonaWeapon() {
      if (this.personaId === -1) {
        return;
      }
      this.weaponList = []
      this.weaponLoading = true
      batchQueryPersonaWeaponApi(this.personaId, null, 1000).then((res) => {
        this.weaponInitialized = true;
        this.weaponList = res['data']['data']
      }).finally(() => {
        this.weaponLoading = false
      })
    },
    batchQueryPersonaVehicle() {
      if (this.personaId === -1) {
        return;
      }
      this.vehicleList = []
      this.vehicleLoading = true
      batchQueryPersonaVehicleApi(this.personaId, null, 1000).then((res) => {
        this.vehicleInitialized = true;
        this.vehicleList = res['data']['data']
      }).finally(() => {
        this.vehicleLoading = false
      })
    },
    matchPageChange(pageNumber) {
      this.matchPageNumber = pageNumber
      this.batchQueryPersonaMatch()
    },
    batchQueryPersonaMatch() {
      if (this.personaId === -1) {
        return;
      }
      this.matchList = []
      this.matchLoading = true
      batchQueryPersonaMatchApi(this.personaId, null, this.matchPageNumber, this.matchPageSize).then((res) => {
        this.matchInitialized = true;
        this.matchList = res['data']['data']['pageData']
        this.matchPageNumber = res['data']['data']['pageNumber']
        this.matchPageSize = res['data']['data']['pageSize']
        this.matchTotal = res['data']['data']['total']
      }).finally(() => {
        this.matchLoading = false
      })
    },
    batchQueryReport() {
      if (this.personaId === -1) {
        return
      }
      batchQueryReportApi(this.personaId, null).then((res) => {
        this.reportList = res['data']['data']
      })
    },
    queryPersonaStats() {
      if (this.personaId === -1) {
        return
      }
      this.statsLoading = true;
      queryPersonaStatsApi(this.personaId, null).then((res) => {
        this.personaInfo = res['data']['data']
      }).finally(() => {
        this.statsLoading = false
      })
    },
    batchQueryPersonaRecentServer() {
      batchQueryPersonaRecentServerApi(this.personaId, null).then((res)=>{
        this.statsInitialized = true;
        this.recentServerList = res['data']['data'].slice(0, 6);
      })
    },
    navigateToServerDetail(row) {
      let routeUrl = this.$router.resolve({
        path: "/server/info",
        query: {
          gameId: row['gameId'],
          from: 2
        }
      });
      window.open(routeUrl .href, '_blank');
    },
    getServerTypeTextColor(serverType) {
      if (serverType === 1) {
        return '#98FB98'
      }
      return '#87CEFF'
    },
    handleBack() {
      if (window.history.length <= 1) {
        window.close()
      }
      else {
        this.$router.back()
      }
    }
  }
}
</script>

<style scoped>
.server_table ::v-deep.el-table .el-table__row:hover {
  background-color: #f5f5f5; /* 设置悬停时的背景颜色 */
  cursor: pointer; /* 将鼠标样式设置为手型 */
}
/** 表格 **/
::v-deep .el-table, ::v-deep .el-table__expanded-cell{
  background-color: transparent;
}
::v-deep .el-table th,
::v-deep .el-table tr,
::v-deep .el-table td {
  background-color: transparent;
}
::v-deep .el-table tbody tr:hover>td {
  background: rgb(255, 255, 255) !important;
  color: #000000 !important;
}
::v-deep .el-table tr,
::v-deep .el-table td {
  border-bottom: none !important;
}
::v-deep .el-table .cell {
  /*padding-left: 10px;*/
  /*padding-right: 0;*/
}
::v-deep .el-table__body-wrapper::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::v-deep .el-table__body-wrapper::-webkit-scrollbar-thumb {
  background-color: #ddd;
  border-radius: 3px;
}
::v-deep .el-table::before {
  height: 0;
}
/***********************************************************/

/** a-card卡片黑色风格 **/
.ant-card {
  background: rgba(17,17,17,0.7);
}
::v-deep .ant-card-head {
  color: white;
  background-color: #1c1c1c;
  border-bottom: none;
}
::v-deep .ant-card-body {
  padding: 0;
}
.ant-card-grid {
  box-shadow: none;
}
::v-deep .ant-card-head-title {
  font-size: 20px;
}
/***********************************************************/

/** 内部tabs切换样式 **/
.el-tabs--border-card {
  background-color: transparent;
  box-shadow: none;
  border: none;
}
::v-deep .el-tabs__content {
  padding: 20px 0 0;
}
::v-deep .el-tabs__header {
  background-color: rgba(17,17,17,0.7);
  border-color: transparent;
}
::v-deep .el-tabs--border-card>.el-tabs__header .el-tabs__item.is-active {
  background-color: #0e0e0e;
  border-left-color: transparent;
  border-right-color: transparent;
  color: white;
}
::v-deep .el-tabs--border-card>.el-tabs__header .el-tabs__item:not(.is-disabled):hover {
  color: white;
}
::v-deep .el-tabs__nav-next,::v-deep .el-tabs__nav-prev {
  line-height: 39px;
  width: 20px;
  text-align: center;
}
/***********************************************************/


/** 信息展示 **/
::v-deep .ant-statistic-title {
  color: #b7b7b7;
}
::v-deep .ant-statistic-content {
  color: white;
}
/***********************************************************/

::v-deep .el-loading-spinner .el-loading-text {
  color: white;
}
::v-deep .el-loading-spinner .path {
  stroke: white;
}


/** <576px **/
@media screen and (max-width: 576px) {
  ::v-deep .ant-statistic-content {
    font-size: 18px;
  }
  ::v-deep .ant-statistic-content-value-decimal {
    font-size: 18px;
  }
  ::v-deep .ant-statistic-title {
    font-size: 18px;
    margin-bottom: 0;
    margin-top: 5px;
  }
  .personaNameText {
    font-size: 21px;
    margin-top: 10px;
  }
  .emblems {
    width: 120px;
    height: 120px;
  }
}

/** ≥576px **/
@media screen and (min-width: 576px) {
  ::v-deep .ant-statistic-content {
    font-size: 20px;
  }
  ::v-deep .ant-statistic-content-value-decimal {
    font-size: 20px;
  }
  ::v-deep .ant-statistic-title {
    font-size: 18px;
  }
  .personaNameText {
    font-size: 24px;
  }
  .emblems {
    width: 105px;
    height: 105px;
  }
}
/** ≥768px **/
@media screen and (min-width: 642px) {
  ::v-deep .ant-statistic-content {
    font-size: 22px;
  }
  ::v-deep .ant-statistic-content-value-decimal {
    font-size: 22px;
  }
  ::v-deep .ant-statistic-title {
    font-size: 20px;
  }
  .personaNameText {
    font-size: 24px;
  }
  .emblems {
    width: 105px;
    height: 105px;
  }
}
/** ≥768px **/
@media screen and (min-width: 768px) {
  ::v-deep .ant-statistic-content {
    font-size: 22px;
  }
  ::v-deep .ant-statistic-content-value-decimal {
    font-size: 22px;
  }
  ::v-deep .ant-statistic-title {
    font-size: 20px;
  }
  .personaNameText {
    font-size: 24px;
  }
  .emblems {
    width: 105px;
    height: 105px;
  }
}
/** ≥992px **/
@media screen and (min-width: 992px) {
  ::v-deep .ant-statistic-content {
    font-size: 22px;
  }
  ::v-deep .ant-statistic-content-value-decimal {
    font-size: 22px;
  }
  ::v-deep .ant-statistic-title {
    font-size: 20px;
  }
  .personaNameText {
    font-size: 24px;
  }
  .emblems {
    width: 110px;
    height: 110px;
  }
}
/** ≥1200px **/
@media screen and (min-width: 1200px) {
  ::v-deep .baseData>.ant-statistic-content,::v-deep .infoData>.ant-statistic-content {
    font-size: 28px;
  }
  ::v-deep .ant-statistic-content-value-decimal {
    font-size: 28px;
  }
  ::v-deep .baseData>.ant-statistic-title,::v-deep .infoData>.ant-statistic-title {
    font-size: 18px;
    margin-top: 5px;
  }
  .personaNameText {
    font-size: 24px;
  }
  .emblems {
    width: 115px;
    height: 115px;
  }
}
/** ≥1600px **/
@media screen and (min-width: 1600px) {
  ::v-deep .baseData>.ant-statistic-content,::v-deep .infoData>.ant-statistic-content {
    font-size: 28px;
  }
  ::v-deep .ant-statistic-content-value-decimal {
    font-size: 28px;
  }
  ::v-deep .baseData>.ant-statistic-title,::v-deep .infoData>.ant-statistic-title {
    font-size: 18px;
  }
  .personaNameText {
    font-size: 26px;
  }
  .emblems {
    width: 115px;
    height: 115px;
  }
}

</style>